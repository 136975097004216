import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { register } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

// 스와이퍼 모듈 등록
register();

/**
 * SwiperJs 제어 디렉티브
 * @link https://swiperjs.com
 */
@Directive({
  selector: '[swiper]',
  standalone: true,
})
export class SwiperDirective implements AfterViewInit {
  /**
   * 스와이퍼 객체
   */
  private swiperElement: any;

  /**
   * 수동 초기화 (문제는 없으나 바르게 작동하고 있는지 불확실)
   */
  @HostBinding('init') init = false;

  /**
   * 스와이퍼 옵션
   */
  @Input() swiper?: SwiperOptions;

  /**
   * 스와이퍼 초기화 완료시
   */
  @Output() swiperInit = new EventEmitter();

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    // 커스텀 html 엘리먼트 (<swiper-container>)
    this.swiperElement = this.elementRef.nativeElement;
    // TODO: 모듈 injection 검토
    // 모듈 적용
    const swiperParams: SwiperOptions = {
      ...this.swiper,
      modules: [Autoplay, Navigation, Pagination],
    };

    // 옵션 적용
    Object.assign(this.swiperElement, swiperParams);

    // 초기화
    this.swiperElement.initialize();
    // 초기화 완료 이벤트 발생
    this.swiperInit.emit(this.swiperElement.swiper);
  }
}
