import { NgModule } from '@angular/core';
import { HtmlToPlainPipe } from './html-to-plain.pipe';
import { LocalePipe } from './locale.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SplitPipe } from './split.pipe';
import { TmEnumPipe } from './tm-enum.pipe';
import { TourTermPipe } from './tour-term.pipe';
import { TranslateWithGooglePipe } from './translate-with-google.pipe';
import { TypeCastingPipe } from './type-casting.pipe';

@NgModule({
  imports: [
    SafeHtmlPipe,
    LocalePipe,
    HtmlToPlainPipe,
    SplitPipe,
    TourTermPipe,
    TranslateWithGooglePipe,
    TypeCastingPipe,
    TmEnumPipe,
  ],
  exports: [
    SafeHtmlPipe,
    LocalePipe,
    HtmlToPlainPipe,
    SplitPipe,
    TourTermPipe,
    TranslateWithGooglePipe,
    TypeCastingPipe,
    TmEnumPipe,
  ],
})
export class PipesModule {}
