import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { IHomeScreen } from '../repository/home-screen/home-screen.model';
import { HomeScreenService } from '../services/home-screen.service';

/**
 * 웹사이트 설정 검사 디렉티브
 */
@Directive({
  selector: '[homeScreen]',
  standalone: true,
})
export class HomeScreenDirective {
  /**
   * 뷰 컨테이너 레퍼런스 중복 동작 방지용 플래그
   */
  #hasView = false;

  /**
   * 홈페이지 설정 키
   */
  #key!: keyof IHomeScreen;

  /**
   * 홈페이지 설정 값
   */
  #valueShouldBe: any = true;

  /**
   * 홈페이지 기능 사용 여부 판단
   *
   * 입력한 키의 값이 falsy이면 뷰 렌더링 안함
   */
  @Input('homeScreen') set homeScreen(key: keyof IHomeScreen) {
    this.#key = key;
    this.updateView();
  }

  /**
   * 비교 할 값
   *
   * 이 값이 홈페이지 기능 설정 값과 일치해야 사용하는 기능임
   *
   * @default true
   */
  @Input('homeScreenValue') set homeScreenValue(valueShouldBe: any) {
    this.#valueShouldBe = valueShouldBe;
    this.updateView();
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private homeScreenService: HomeScreenService,
  ) {}

  /**
   * 화면 갱신
   */
  private updateView(): void {
    if (!this.homeScreenService.isAvail(this.#key, this.#valueShouldBe)) {
      this.viewContainerRef.clear();
      this.#hasView = false;
      return;
    }

    if (!this.#hasView) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.#hasView = true;
    }
  }
}
