import { Pipe, PipeTransform } from '@angular/core';

/**
 * HTML 텍스트를 일반 텍스트로 변환
 */
@Pipe({
  name: 'htmlToPlain',
  standalone: true,
})
export class HtmlToPlainPipe implements PipeTransform {
  private parser = new DOMParser();

  transform(value: string, ...args: never[]): string {
    return this.parser.parseFromString(
      // <br> -> \n 변경
      // 랜더링되지 않으면 innerText에서 \n이 표현되지 않음
      value.replace(/<br[^>]*>/g, '\n'),
      'text/html',
    ).documentElement.innerText;
  }
}
