<img
  [src]="imgSrc"
  (load)="onLoad($event)"
  (error)="onError($event)"
  [ngClass]="{ 'hover-zoom': hoverZoom, hide: isRetrying }"
  [attr.loading]="loading"
/>

<div
  *ngIf="isRetrying"
  class="spinner spinner-border"
  [style.width.px]="size"
  [style.height.px]="size"
  role="status"
></div>
