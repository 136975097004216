import { NgModule } from '@angular/core';

import { AdminRoleDirective } from './admin-role.directive';
import { BrandFeatureDirective } from './brand-feature.directive';
import { FormValidatorDirective } from './form-validator.directive';
import { HomeScreenDirective } from './home-screen.directive';
import { InputCheckDirective } from './input-check.directive';
import { PermissionDirective } from './permission.directive';
import { SwiperDirective } from './swiper.directive';

@NgModule({
  imports: [
    AdminRoleDirective,
    BrandFeatureDirective,
    FormValidatorDirective,
    HomeScreenDirective,
    InputCheckDirective,
    PermissionDirective,
    SwiperDirective,
  ],
  exports: [
    AdminRoleDirective,
    BrandFeatureDirective,
    FormValidatorDirective,
    HomeScreenDirective,
    InputCheckDirective,
    PermissionDirective,
    SwiperDirective,
  ],
})
export class DirectivesModule {}
