/* eslint-disable camelcase */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  IAccount
} from 'src/lib/auth/account.model';
import { CacheableRepositoryService } from 'src/lib/repository/abstract-cacheable-repository.service';
import { StorageService } from 'src/lib/services/storage.service';

/**
 * 페이위드 토큰 인터페이스
 */
export interface OauthResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
}

/**
 * 인증(토큰) 정보 취급 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * 토큰 타입
   */
  tokenType?: string;

  /**
   * 액세스 토큰. API 요청에 사용
   */
  accessToken?: string;

  /**
   * 갱신 토큰. 액세스토큰 만료 후 갱신토큰이 있다면 refresh()를 통해 accessToken을 갱신한다
   */
  refreshToken?: string;

  /**
   * 액세스 토큰의 만료일시
   */
  expireDttm?: Date;

  /**
   * 로그인 이벤트
   */
  loginSubject$: Subject<IAccount> = new Subject();

  /**
   * 로그아웃 이벤트
   */
  logoutSubject$: Subject<void> = new Subject();

  /**
   * 현재 로그인 한 사용자
   *
   * @private
   */
  #account?: IAccount;

  /**
   * 로그아웃 진행 중 여부
   *
   * loginSubject$ 루프 방지를 위해 사용
   */
  private doingLogout = false;

  /**
   * 현재 브랜드
   * @deprecated 삭제 예정
   */
  private currentBrand: any = null;

  /**
   * 사용자 정보 호출 API
   *
   * 중복 호출 방지용
   */
  private getAccountApi$?: Observable<IAccount>;

  // TODO: 인젝션 또는 브랜드마다 클라이언트 별도 관리하는 경우 검토
  /**
   * 클라이언트 정보
   */
  private readonly client = 'asoyamanami:paywith1234';

  // TODO: 인젝션 또는 2개 이상의 인증서버를 이용하는 경우 검토
  /**
   * 인증 API URL
   */
  private readonly authUrl = `${environment.apiServerUrl}/oauth/token`;

  get account(): IAccount {
    return this.#account!;
  }

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private storageService: StorageService,
  ) {
    // FIXME: 삭제
    this.temp();
    this.initAuth();

    // 현재 브랜드 설정
    this.currentBrand = this.account?.brandId;
  }

  /**
   * 로그인
   */
  login(
    userName: string,
    password: string,
    role?: string,
    brandId?: number,
  ): Observable<IAccount> {
    this.clearAuth();

    // of(true)는 의미 없는, 코드포맷만을 위함임
    return of(true).pipe(
      mergeMap(() => {
        return this.getNewAuth$(userName, password, role, brandId);
      }),
      mergeMap(() => {
        return this.getAccount$();
      }),
    );
  }

  /**
   * 로그아웃
   */
  logout(reason?: string): void {
    // loginSubject$ 루프 방지, 이미 로그아웃 중이면 건너띔
    if (!this.doingLogout) {
      // 로그아웃 시작
      this.doingLogout = true;

      this.clearAuth();
      this.loginSubject$.next(null!);
      this.logoutSubject$.next();
      // 현재 브랜드 초기화
      this.currentBrand = null;
      // 로그인 페이지로 이동
      this.router.navigateByUrl('/login');

      // FIXME
      // 로그아웃 사유가 있다면 표시
      if (reason) {
        alert(reason);
      }

      // 로그아웃 완료
      this.doingLogout = false;
    }
  }

  /**
   * 로그인 한 계정 정보 획득
   */
  getAccount$(): Observable<IAccount> {
    if (this.account) {
      // 이미 가지고 있다면 반환
      return of(this.account);
    }

    // 중복 호출 아니면
    if (!this.getAccountApi$) {
      // 인증정보 획득
      this.getAccountApi$ = this.httpClient
        .get<IAccount>(`${environment.apiServerUrl}/api/account`)
        .pipe(
          // 동시에 하나만 실행
          shareReplay(1),
          tap((account: IAccount) => {
            this.#account = account;
            this.loginSubject$.next(account);
            this.getAccountApi$ = null!;

            // FIXME: 각 캐싱 서비스에서 logout 서브젝트 구독하여 처리
            // 로그인 정보의 브랜드가 현재 브랜드와 다르면
            // 캐싱 목록 초기화
            if (this.account?.brandId !== this.currentBrand) {
              this.currentBrand = this.account?.brandId;
              CacheableRepositoryService.clearCacheListAll();
            }
          }),
        );
    }

    return this.getAccountApi$;
  }

  /**
   * 새 인증정보 요청
   */
  getNewAuth$(
    username: string,
    password: string,
    role?: string,
    brandId?: number,
  ): Observable<OauthResponse> {
    const params = {
      grant_type: 'password',
      username,
      password,
      ...(role && { role }),
      ...(brandId && { brandId }),
    };

    return this.getAuth$(params);
  }

  /**
   * 리프레시 토큰을 이용하여 엑세스 토큰 갱신
   */
  getRefreshAuth$(): Observable<OauthResponse> {
    const params = {
      grant_type: 'refresh_token',
      refresh_token: this.refreshToken,
    };

    return this.getAuth$(params).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        this.logout('invalid_token');

        return throwError(() => httpErrorResponse);
      }),
    );
  }

  refreshAccount$(): Observable<IAccount> {
    // 중복 호출 아니면
    if (!this.getAccountApi$) {
      // 인증정보 획득
      this.getAccountApi$ = this.httpClient
        .get<IAccount>(`${environment.apiServerUrl}/api/account`)
        .pipe(
          // 동시에 하나만 실행
          shareReplay(1),
          tap((account: IAccount) => {
            this.#account = account;
            this.loginSubject$.next(account);
            this.getAccountApi$ = null!;

            // FIXME: 각 캐싱 서비스에서 logout 서브젝트 구독하여 처리
            // 로그인 정보의 브랜드가 현재 브랜드와 다르면
            // 캐싱 목록 초기화
            if (this.account.brandId !== this.currentBrand) {
              this.currentBrand = this.account.brandId;
              CacheableRepositoryService.clearCacheListAll();
            }
          }),
        );
    }

    return this.getAccountApi$;
  }

  /**
   * @deprecated 구 인증정보 삭제용 임시 메소드
   */
  private temp(): void {
    localStorage.removeItem('tournity.mngr.accessToken');
    localStorage.removeItem('tournity.mngr.expireDttm');
    localStorage.removeItem('tournity.mngr.auth');
    localStorage.removeItem('tournity.mngr.refreshToken');
    localStorage.removeItem('tournity.mngr.loginInfo');
  }

  /**
   * 인증정보 삭제
   */
  private clearAuth(): void {
    // 클래스 변수에서 삭제
    this.accessToken = null!;
    this.refreshToken = null!;
    this.expireDttm = null!;
    this.#account = null!;
    this.getAccountApi$ = null!;

    // 브라우저에서 삭제
    this.storageService.delete(`tokenType`);
    this.storageService.delete(`accessToken`);
    this.storageService.delete(`refreshToken`);
    this.storageService.delete(`expireDttm`);
  }

  /**
   * 인증정보 초기화
   */
  private initAuth(): void {
    // 브라우저에서 획득
    this.tokenType = this.storageService.get(`tokenType`);
    this.accessToken = this.storageService.get(`accessToken`);
    this.refreshToken = this.storageService.get(`refreshToken`);
    this.expireDttm = new Date(this.storageService.get(`expireDttm`));

    if (!this.accessToken) {
      return;
    }

    // TODO: 새로고침해도 로그인 서브젝트를 실행시켜야 하는지 검토
    // setTimeout 빼면 AuthInterceptor와 순환참조(HttpClient) 발생
    setTimeout(() => {
      this.getAccount$()
        .pipe(
          tap((account) => {
            this.loginSubject$.next(account);
          }),
        )
        .subscribe();
    });
  }

  /**
   * 인증정보 저장
   */
  private setAuth(oauthResponse: OauthResponse): void {
    const { token_type, access_token, refresh_token, expires_in } =
      oauthResponse;

    // 클래스 변수에 저장
    this.tokenType = token_type;
    this.accessToken = access_token;
    this.refreshToken = refresh_token;
    this.expireDttm = new Date(Date.now() + expires_in * 1000);

    // 브라우저에 저장
    this.storageService.set('tokenType', this.tokenType);
    this.storageService.set('accessToken', this.accessToken);
    this.storageService.set('refreshToken', this.refreshToken);
    this.storageService.set('expireDttm', this.expireDttm);
  }

  /**
   * 인증 요청
   */
  private getAuth$(params: any): Observable<OauthResponse> {
    // 요청 헤더 설정
    const headers = {
      Authorization: `Basic ${btoa(this.client)}`,
    };

    return this.httpClient
      .post<OauthResponse>(this.authUrl, null, {
        headers,
        params,
      })
      .pipe(
        tap((token) => {
          this.setAuth(token);
        }),
      );
  }
}
