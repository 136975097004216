import { Injectable } from '@angular/core';

/**
 * 번역 가능 언어
 */
export type Language =
  /**
   * 한국어
   */
  | 'ko'
  /**
   * 영어
   */
  | 'en'
  /**
   * 일본어
   */
  | 'ja'
  /**
   * 중국어 간체
   */
  | 'zh-CN'
  /**
   * 중국어 번체 (대만)
   */
  | 'zh-TW'
  /**
   * 베트남어
   */
  | 'vi'
  /**
   * 네팔어
   */
  | 'ne'
  /**
   * 인도네시아어
   */
  | 'id'
  /**
   * 필리핀어
   */
  | 'tl'
  /**
   * 태국어
   */
  | 'th';

// TODO: 공통 기능으로 채택되면 다른 언어 관련 기능들과 통합 검토
/**
 * 구글 번역 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class GoogleTranslationService {
  // TODO: 설정화 검토
  /**
   * 기본 언어
   */
  pageLanguage: Language = 'ko';

  // TODO: 설정화 검토
  /**
   * 구글 자동번역 가능 언어
   */
  includedLanguages: Language[] = ['ko', 'ja', 'en'];

  /**
   * 구글 언어 선택기 생성 타겟 엘리먼트
   */
  private googleTranslateElement?: HTMLDivElement;

  /**
   * 구글 번역 설정 스크립트 엘리먼트
   */
  private googleTranslateConfigScript?: HTMLScriptElement;

  /**
   * 구글 번역 초기화 스크립트 엘리먼트
   */
  private googleTranslateInitScript?: HTMLScriptElement;

  /**
   * 구글 번역 스타일 엘리먼트
   */
  private googleTranslateStyle?: HTMLStyleElement;

  constructor() {}

  /**
   * 초기화
   */
  init(): void {
    if (!this.pageLanguage) {
      throw new Error('pageLanguage not provided.');
    }

    if (!this.includedLanguages?.length) {
      throw new Error('includedLanguages not provided.');
    }

    // 2회 이상 호출되는 경우를 대비하여 삭제 후 생성
    this.removeElement();
    this.createElement();
  }

  /**
   * 번역 언어 변경
   */
  changeLanguage(language: Language, retryCnt = 0): void {
    // 일치하는 언어가 없으면
    if (this.includedLanguages.indexOf(language) < 0) {
      // 오류
      throw new Error('Provided language is not supported.');
    }

    // 언어 선택 select 엘리먼트
    const languageSelector = document.getElementsByClassName(
      'goog-te-combo',
    )[0] as HTMLSelectElement;

    // 이 단계에 언어 선택기와 선택 가능한 언어 없으면 200ms마다 최대 5회 재호출
    if (!languageSelector?.children?.length && retryCnt <= 5) {
      setTimeout(() => {
        this.changeLanguage(language, retryCnt + 1);
      }, 200);

      return;
    }

    // 값 변경
    languageSelector.value = language;
    // 이벤트 발생
    languageSelector.dispatchEvent(new Event('change'));
  }

  /**
   * 번역 언어 초기화
   */
  resetLanguage(): void {
    // 상단 구글 번역 엘리먼트
    const div = document.getElementsByClassName(
      'skiptranslate',
    )[0] as HTMLDivElement;

    if (!div) {
      return;
    }

    let iframe: HTMLIFrameElement = null!;

    // 반복하면서
    div.childNodes.forEach((value) => {
      // iframe 검색
      if (value.nodeName === 'IFRAME') {
        iframe = value as HTMLIFrameElement;
      }
    });

    // iframe 찾지 못했으면
    if (!iframe) {
      // 종료
      return;
    }

    const innerDoc = iframe.contentDocument || iframe.contentWindow!.document;
    // 버튼 목록
    const buttonMaybeRestore = innerDoc.getElementsByTagName('button');

    for (let i = 0; i < buttonMaybeRestore.length; i += 1) {
      // 초기화 버튼이면
      if (buttonMaybeRestore[i].id.indexOf('restore') >= 0) {
        // 클릭 이벤트 발생
        buttonMaybeRestore[i].dispatchEvent(new Event('click'));
        // 메소드 종료
        return;
      }
    }
  }

  /**
   * 번역 엘리먼트 생성
   */
  private createElement(): void {
    this.googleTranslateElement = document.createElement('div');
    this.googleTranslateElement.id = 'google_translate_element';

    document
      .getElementsByTagName('body')[0]
      .appendChild(this.googleTranslateElement);

    this.googleTranslateConfigScript = document.createElement('script');
    this.googleTranslateConfigScript.type = 'text/javascript';
    this.googleTranslateConfigScript.text = `
      function googleTranslateElementInit() {
        new google.translate.TranslateElement(
          {
            includedLanguages: '${this.includedLanguages}',
            multilanguagePage: true,
            // 번역 제안 안함
            autoDisplay: false,
          },
          'google_translate_element'
        );
      }`;

    document
      .getElementsByTagName('head')[0]
      .appendChild(this.googleTranslateConfigScript);

    this.googleTranslateInitScript = document.createElement('script');
    // 로드 완료되면 googleTranslateElementInit라는 이름의 function 실행
    // https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit
    // https://translate.googleapis.com/_/translate_http/_/js/k=translate_http.tr.ko.1usx5Qd-eD0.O/d=1/exm=el_conf/ed=1/rs=AN8SPfpE8Y3HZCwIZrHoPWmKMEFHbxFEgw/m=el_main
    this.googleTranslateInitScript.src =
      'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';

    document
      .getElementsByTagName('head')[0]
      .appendChild(this.googleTranslateInitScript);

    this.googleTranslateStyle = document.createElement('style');
    // 구글 번역 엘리먼트들 숨김
    this.googleTranslateStyle.textContent = `
    body[style*="top"] {
      top: initial !important;
    }

    .skiptranslate,
    #google_translate_element {
      display: none;
    }
    `;

    document
      .getElementsByTagName('head')[0]
      .appendChild(this.googleTranslateStyle);
  }

  /**
   * 번역 엘리먼트 삭제
   */
  private removeElement(): void {
    this.googleTranslateStyle?.remove();
    this.googleTranslateInitScript?.remove();
    this.googleTranslateConfigScript?.remove();
    this.googleTranslateElement?.remove();
  }
}
