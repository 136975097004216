import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from 'src/lib/directives/directives.module';
import { PwImgModule } from 'src/lib/legacy/components/pw-img/pw-img.module';
import { PipesModule } from 'src/lib/pipes/pipes.module';
import { LoadingComponent } from '../loading/loading.component';

// TODO: 더이상 사용하지 않는 모듈 삭제 검토
// TODO: 잘 사용되지 않는 모듈 삭제 검토
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LoadingComponent,
    PwImgModule,
    DirectivesModule,
    PipesModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    LoadingComponent,
    PwImgModule,
    DirectivesModule,
    PipesModule,
  ],
})
export class SharedModule {}
