import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Permission } from 'src/lib/auth/account.model';
import { PermissionService } from '../services/permission.service';

/**
 * 권한 검사 디렉티브
 */
@Directive({
  selector: '[permission]',
  standalone: true,
})
export class PermissionDirective {
  /**
   * 뷰 컨테이너 레퍼런스 중복 동작 방지용 플래그
   */
  #hasView = false;

  /**
   * 권한
   */
  #permission!: Permission;

  /**
   * 권한
   */
  @Input('permission')
  set permission(permission: Permission) {
    this.#permission = permission;
    this.updateView();
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private permissionService: PermissionService,
  ) {}

  /**
   * 화면 갱신
   */
  private updateView(): void {
    if (!this.permissionService.isAvail(this.#permission)) {
      this.viewContainerRef.clear();
      this.#hasView = false;
      return;
    }

    if (!this.#hasView) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.#hasView = true;
    }
  }
}
