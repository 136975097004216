import { Pipe, PipeTransform } from '@angular/core';
import { TmEnum, tmEnum } from '../repository/temairazu/tm.model';

/**
 * 테마이라즈 enum 파이프 옵션
 */
export interface TmEnumOption {
  include?: number[];
  exclude?: number[];
}

/**
 * 테마이라즈 enum 숫자 > 문자 변환 파이프
 */
@Pipe({
  name: 'tmEnum',
  pure: true,
  standalone: true,
})
export class TmEnumPipe implements PipeTransform {
  transform(tmObject: any, key: keyof TmEnum, option?: TmEnumOption): string {
    if (!tmObject || !key) {
      return '';
    }

    // value는 0일수도 있으므로 ??
    const value: number | number[] = tmObject[key] ?? [];
    const numberKeyValue: { [key: number]: string } = tmEnum[key];
    const returnValueList: string[] = [];
    let valueList: number[];

    // 배열로 형변환
    if (typeof value === 'number') {
      valueList = [value];
    } else {
      valueList = value;
    }

    if (option) {
      const { include, exclude } = option;

      // 포함 필터
      if (include?.length) {
        valueList = valueList.filter((numberValue) => {
          return include.indexOf(numberValue) >= 0;
        });
      }

      // 제외 필터
      if (exclude?.length) {
        valueList = valueList.filter((numberValue) => {
          return exclude.indexOf(numberValue) < 0;
        });
      }
    }

    valueList.forEach((number) => {
      returnValueList.push(numberKeyValue[number]);
    });

    return returnValueList.join(', ');
  }
}
