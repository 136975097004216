import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, shareReplay, tap } from 'rxjs';
import { PageRepositoryService } from './abstract-repository.service';
import { IHalPageResponse, IPageResponse } from './page.model';

@Injectable({
  providedIn: 'root',
})
export abstract class CacheableRepositoryService<
  T extends Record<string, any> = any,
  T2 extends IHalPageResponse | IPageResponse = IHalPageResponse,
> extends PageRepositoryService<T, T2> {
  /**
   * 캐싱 서비스 목록
   *
   * 캐싱 목록 초기화에 사용
   */
  // eslint-disable-next-line no-use-before-define
  static cacheableServiceList: CacheableRepositoryService<any, any>[] = [];

  private cacheList: T[] | null = null;

  private cacheList$?: Observable<T[]> | null;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);

    // 캐싱 서비스 목록에 추가
    CacheableRepositoryService.cacheableServiceList.push(this);
  }

  getCacheList(params: any = {}, reset = false): Observable<T[]> {
    if (!reset) {
      if (this.cacheList?.length && this.cacheList.length > 0) {
        return of(this.cacheList);
      }

      if (this.cacheList$) {
        return this.cacheList$;
      }
    }

    const size = params?.size || 100;

    this.cacheList$ = super.findPage({ ...params, size }).pipe(
      shareReplay(),
      map(({ content }) => content),
      tap((content) => {
        this.cacheList = content;
      }),
    );

    return this.cacheList$;
  }

  /**
   * 캐싱 목록 초기화
   */
  clearCacheList(): void {
    this.cacheList = [];
    this.cacheList$ = null;
  }

  /**
   * 모든 캐싱 서비스의 캐싱 목록 초기화
   */
  static clearCacheListAll(): void {
    this.cacheableServiceList.forEach((service) => {
      service.clearCacheList();
    });
  }
}
