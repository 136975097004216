import { Injectable } from '@angular/core';
import { IHomeScreen } from '../repository/home-screen/home-screen.model';

/**
 * 웹사이트 설정 검사 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class HomeScreenService {
  /**
   * 웹사이트 설정
   */
  #homeScreen?: IHomeScreen;

  /**
   * 웹사이트 설정
   * @readonly
   */
  get homeScreen(): IHomeScreen {
    return this.#homeScreen!;
  }

  constructor() {}

  /**
   * 서비스 초기화
   */
  init(homeScreen: IHomeScreen): void {
    this.#homeScreen = homeScreen;
  }

  /**
   * 이용 가능 여부
   * @param key 검사 대상 키
   * @param valueShoudBe 검사 대상의 값이 이 값과 일치할때 이용 가능
   */
  isAvail(key: keyof IHomeScreen, valueShoudBe = true): boolean {
    if (!this.#homeScreen || !key) {
      return false;
    }

    return this.#homeScreen![key] === valueShoudBe;
  }
}
