import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { BrandService } from 'src/lib/services/brand.service';
import { HomeScreenService } from 'src/lib/services/home-screen.service';

@Component({
  selector: 'app-loading',
  standalone: true,
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  /**
   * 로딩 원 크기
   */
  size = 160;

  /**
   * css 애니메이션 전환 스타일
   */
  transition = 'opacity 300ms ease-in-out';

  /**
   * css 배경 이미지
   */
  backgroundImage = '';

  constructor(
    public loadingService: LoadingService,
    private brandService: BrandService,
    private homeScreenService: HomeScreenService,
  ) {}

  ngOnInit(): void {
    this.transition = `opacity ${this.loadingService.animationDurationMs}ms`;
    this.setBackgroundImage();
  }

  /**
   * 배경 이미지 설정
   */
  setBackgroundImage(): void {
    const imageUrl =
      this.homeScreenService.homeScreen?.homepageLogoImageUrl ??
      this.brandService.brand?.logoImgUrl;

    this.backgroundImage = `url(${imageUrl})`;
  }
}
