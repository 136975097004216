import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { IAccount, Permission } from 'src/lib/auth/account.model';
import { AuthService } from 'src/lib/auth/auth.service';
import { IBrand } from 'src/lib/repository/brand/brand.model';
import { AdminRole } from 'src/lib/repository/enums';
import { Utils } from 'src/lib/utils';

/**
 * 권한 검사 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  /**
   * 권한 세트
   */
  private permissionSet: Set<Permission> = new Set();

  /**
   * 현재 로그인 관리자 역할 세트
   */
  private adminRoleSet: Set<AdminRole> = new Set();

  constructor(private authService: AuthService) {
    // 로그아웃하면 역할 세트 초기화
    this.authService.logoutSubject$
      .pipe(
        tap(() => {
          this.adminRoleSet.clear();
        }),
      )
      .subscribe();
  }

  /**
   * 서비스 초기화
   */
  init(brand: IBrand): void {
    const permissionList: Permission[] = Utils.getParsedJson(
      brand.permissionListJson,
      [],
    );

    this.permissionSet = new Set(permissionList);
  }

  /**
   * 관리자인 경우 서비스 초기화
   */
  initAsAdmin(account: IAccount): void {
    const { brandPermissionList, permissionList, roles } = account;
    const brandPermissionSet = new Set(brandPermissionList);

    // 관리자/브랜드 권한의 교집합 배열
    const intersectionList = permissionList.filter((permission) => {
      return brandPermissionSet.has(permission);
    });

    this.permissionSet = new Set(intersectionList);
    this.adminRoleSet = new Set(roles);
  }

  /**
   * 이용 가능 여부
   */
  isAvail(...permissionList: Permission[]): boolean {
    // 권한 입력은 필수
    if (!permissionList?.length) {
      throw new Error('Permission is required');
    }

    // 시스템 관리자는 무조건 통과
    if (this.adminRoleSet.has('ADMIN_SYSTEM')) {
      return true;
    }

    // 모두 통과해야 이용 가능
    const hasPermission = permissionList.every((permission) => {
      return this.permissionSet.has(permission);
    });

    return hasPermission;
  }
}
