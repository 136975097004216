import { Pipe, PipeTransform } from '@angular/core';

/**
 * 긴 문자열을 읽기 쉽게 잘라주는 파이프
 * 예) 선불카드번호 1111222233334444 -> 1111 2222 3333 4444
 */
@Pipe({
  name: 'split',
  standalone: true,
})
export class SplitPipe implements PipeTransform {
  transform(value: string, size = 4, splitter = ' '): string {
    if (!value) {
      return '';
    }

    const loopCount = Math.ceil(value.length / 4);
    const strArray: string[] = [];

    for (let i = 1; i <= loopCount; i += 1) {
      const start = (i - 1) * size;
      const end = i * size;
      const string = value.substring(start, end);
      strArray.push(string);
    }

    return strArray.join(splitter);
  }
}
