<div
  class="loading-spinner-wrapper"
  [style.display]="loadingService.display"
  [style.opacity]="loadingService.opacity"
  [style.transition]="transition"
  [style.background-image]="backgroundImage"
>
  <div
    class="spinner-border text-primary"
    [style.width.px]="size"
    [style.height.px]="size"
    role="status"
  ></div>
</div>
