export type TmEnum = typeof tmEnum;

export const tmEnum = {
  RoomCategory: {
    1: '洋室',
    2: '和室',
    3: '和洋室',
    4: 'その他',
  },

  RoomType: {
    1: 'シングル',
    2: 'セミダブル',
    3: 'ダブル',
    4: 'ツイン',
    5: 'トリプル',
    6: '4ベッド',
    7: '5ベッド',
    8: 'その他',
  },

  Grade: {
    0: '設定なし',
    1: 'スタンダード',
    2: 'レギュラー',
    3: 'エコノミー',
    4: 'スーペリア',
    5: 'デラックス',
    6: '特別室・スイート・離れ',
  },

  View: {
    0: '設定なし',
    1: '湖側',
    2: '山側',
    3: '海側',
    4: '川側',
    5: '港側',
    6: '庭園側',
    7: '街側',
  },

  Equipment: {
    0: '風呂',
    1: '露天風呂付客室',
    2: 'シャワー',
    3: 'トイレ',
    4: '洗浄機付トイレ',
    5: 'エアコン',
    6: '空気清浄器',
    7: '加湿器',
    8: '冷蔵庫',
    9: 'キッチン',
    10: 'ミニバー',
    11: 'コーヒー',
    12: 'お茶',
    13: '電気ポット',
    14: 'テレビ',
    15: 'ケーブルテレビ',
    16: '電話',
    17: 'セーフティボックス',
    18: 'インターネット(有線)',
    19: 'インターネット(Wifi)',
    20: 'ドライヤー',
    21: 'ズボンプレッサー',
    22: 'パジャマ',
    23: 'スリッパ',
    24: 'タオル',
    25: 'リネン',
    26: 'シーツ',
    27: 'トイレットペーパー',
    28: '洗濯機',
    29: 'エクストラベッド',
  },

  Amenity: {
    0: 'シャンプー・コンディショナー ',
    1: 'ボディソープ・石鹸',
    2: 'ハンドソープ',
    3: '歯ブラシ',
    4: '洗顔料',
    5: 'クレンジング',
    6: '化粧水',
    7: '乳液',
    8: 'ひげ剃り',
    9: 'シェービングフォーム ',
    10: 'ヘアブラシ',
    11: 'シャワーキャップ ',
    12: '衣料消臭用スプレー',
  },

  Feature: {
    0: 'バリアフリー ',
    1: '禁煙',
    2: '喫煙',
    3: 'ペット可',
  },

  Reason: {
    0: '騒音あり',
    1: '冷暖房なし',
    2: '階段利用のみ',
    3: '眺望なし',
    4: '和式トイレ',
  },

  AcceptanceCondition: {
    0: '男性専用',
    1: '女性専用',
    2: '部屋指定不可',
    3: '相部屋',
  },

  PicturesCategory: {
    1: 'その他',
    2: '部屋',
    3: '設備',
    4: '食事',
    5: 'サービス',
    6: '施設',
  },

  Payment: {
    0: '現地決済',
    1: '事前決済',
    2: '事後決済',
    3: '現地決済 / 事前決済',
  },

  OptionalItems: {
    1: '男性限定',
    2: '女性限定',
    3: '朝食付き',
    4: '夕食付き',
    5: '昼食付き',
  },
};
