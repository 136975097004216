import { Pipe, PipeTransform } from '@angular/core';

/**
 * HTML 템플릿에서 타입 캐스팅을 가능하게 해주는 파이프
 *
 * ```ts
 * @Component({
 * templateUrl: '{{ data | as: ISomeInterface }}'
 * })
 * export class ExampleComponent {
 *   ISomeInterface?: ISomeInterface;
 *   data: any;
 * }
 * ```
 */
@Pipe({ name: 'as', pure: true, standalone: true })
export class TypeCastingPipe implements PipeTransform {
  transform<T>(input: unknown, _: T | undefined): T {
    return input as unknown as T;
  }
}
