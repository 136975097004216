import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PwImgComponent } from './pw-img.component';

@NgModule({
  declarations: [PwImgComponent],
  imports: [CommonModule],
  exports: [PwImgComponent],
})
export class PwImgModule {}
