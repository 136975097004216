import {
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, tap } from 'rxjs';

/**
 * 인풋 유효성 메시지 표시 디렉티브
 */
@Directive({
  selector: '[validator]',
  standalone: true,
})
export class FormValidatorDirective implements OnInit, OnDestroy {
  control!: AbstractControl;

  /**
   * 오류 메시지 키밸류
   */
  private errorDictionary: any = {
    required: this.translateService.instant('VALID.required'),
  };

  /**
   * 컴포넌트 파괴 서브젝트
   */
  private destroy$ = new Subject();

  /**
   * 에러 툴팁 엘리먼트
   */
  private errorTooltip?: HTMLDivElement;

  /**
   * 앵귤러 touched
   *
   * 엘리먼트 클래스 변경 감지 중복 동작 방지용
   */
  private isTouched = false;

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private renderer2: Renderer2,
    private ngControl: NgControl,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    if (!this.ngControl?.control) {
      return;
    }

    this.control = this.ngControl.control;
    this.setParentClass();
    this.setMutationObserver();
    this.setStatusChageEvent();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  /**
   * 부모 엘리먼트 클래스 추가
   *
   * 부트스트랩 툴팁 표시하기 위해선 부모 엘리먼트가 position: relative 이어야 함
   */
  private setParentClass(): void {
    const parentNode = this.renderer2.parentNode(this.elementRef.nativeElement);
    this.renderer2.addClass(parentNode, 'position-relative');
  }

  /**
   * 폼 컨트롤 상태 변경 이벤트 설정
   */
  private setStatusChageEvent(): void {
    this.control.statusChanges
      .pipe(
        tap(() => {
          this.setValidationClass();
          this.showErrorTooltip();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  /**
   * 엘리먼트 변경 감지 설정
   */
  private setMutationObserver(): void {
    const observer = new MutationObserver(() => {
      if (
        !this.isTouched &&
        this.elementRef.nativeElement.classList.contains('ng-touched')
      ) {
        this.isTouched = true;
        this.setValidationClass();
        this.showErrorTooltip();
      } else if (
        this.isTouched &&
        this.elementRef.nativeElement.classList.contains('ng-untouched')
      ) {
        this.isTouched = false;
      }
    });

    observer.observe(this.elementRef.nativeElement, {
      attributeFilter: ['class'],
      childList: false,
      characterData: false,
      subtree: false,
    });
  }

  /**
   * 부트스트랩 입력 유효성 클래스 설정
   */
  private setValidationClass(): void {
    this.renderer2.removeClass(this.elementRef.nativeElement, 'is-invalid');
    this.renderer2.removeClass(this.elementRef.nativeElement, 'is-valid');

    if (this.control.touched) {
      if (this.control.valid) {
        this.renderer2.addClass(this.elementRef.nativeElement, 'is-valid');
      } else {
        this.renderer2.addClass(this.elementRef.nativeElement, 'is-invalid');
      }
    }
  }

  /**
   * 부트스트랩 오류 툴팁 표시
   */
  private showErrorTooltip(): void {
    if (!this.control?.errors) {
      return;
    }

    if (this.control.untouched) {
      return;
    }

    // 앵귤러 폼 컨트롤의 첫번째 오류
    const [firstErrorKey] = Object.entries(this.control.errors)
      .filter(([_, value]) => !!value)
      .map(([key]) => key);
    // 번역된 오류
    const errorMessage = this.errorDictionary[firstErrorKey];
    const parentNode = this.renderer2.parentNode(this.elementRef.nativeElement);

    // 이미 툴팁 엘리먼트 있으면 삭제
    if (this.errorTooltip) {
      this.renderer2.removeChild(parentNode, this.errorTooltip);
    }

    // 툴팁 엘리먼트 생성
    const text = this.renderer2.createText(errorMessage);
    this.errorTooltip = this.renderer2.createElement('div');
    this.renderer2.addClass(this.errorTooltip, 'invalid-tooltip');
    this.renderer2.setAttribute(this.errorTooltip, 'translate', 'no');
    this.renderer2.appendChild(this.errorTooltip, text);

    // 형제 엘리먼트
    const nextSibling = this.renderer2.nextSibling(
      this.elementRef.nativeElement,
    );

    if (parentNode) {
      // 형제 있으면
      if (nextSibling) {
        // 나와 형제 사이에 생성
        this.renderer2.insertBefore(parentNode, this.errorTooltip, nextSibling);
      }
      // 없으면
      else {
        // 부모의 마지막 엘리먼트로 생성
        this.renderer2.appendChild(parentNode, this.errorTooltip);
      }
    }
  }
}
