import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Feature } from 'src/lib/auth/account.model';
import { BrandService } from '../services/brand.service';

/**
 * 브랜드 기능 검사 디렉티브
 */
@Directive({
  selector: '[feature]',
  standalone: true,
})
export class BrandFeatureDirective {
  /**
   * 뷰 컨테이너 레퍼런스 중복 동작 방지용 플래그
   */
  #hasView = false;

  /**
   * 브랜드 기능
   */
  #feature!: Feature;

  /**
   * 브랜드 기능
   */
  @Input('feature')
  set brandFeature(feature: Feature) {
    this.#feature = feature;
    this.updateView();
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private brandService: BrandService,
  ) {}

  /**
   * 화면 갱신
   */
  private updateView(): void {
    if (!this.brandService.isAvail(this.#feature)) {
      this.viewContainerRef.clear();
      this.#hasView = false;
      return;
    }

    if (!this.#hasView) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.#hasView = true;
    }
  }
}
