import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { AdminRole, IAccount } from 'src/lib/auth/account.model';
import { AuthService } from 'src/lib/auth/auth.service';

/**
 * 관리자 권한 검사 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class AdminRoleService {
  /**
   * 현재 로그인 관리자 역할 세트
   */
  private adminRoleSet: Set<AdminRole> = new Set();

  constructor(private authService: AuthService) {
    // 로그아웃하면 역할 세트 초기화
    this.authService.logoutSubject$
      .pipe(
        tap(() => {
          this.adminRoleSet.clear();
        }),
      )
      .subscribe();
  }

  /**
   * 서비스 초기화
   */
  init(account: IAccount): void {
    this.adminRoleSet = new Set(account.roles);
  }

  /**
   * 이용 가능 여부
   */
  isAvail(...roleList: AdminRole[]): boolean {
    // 역할 입력은 필수
    if (!roleList?.length) {
      throw new Error('Role is required');
    }

    if (this.adminRoleSet.has('ADMIN_SYSTEM')) {
      return true;
    }

    // 하나라도 통과하면 이용 가능
    const hasRole = roleList.some((role) => {
      return this.adminRoleSet.has(role);
    });

    return hasRole;
  }
}
