import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { IBrand } from 'src/lib/repository/brand/brand.model';
import { StorageService } from 'src/lib/services/storage.service';
import { Utils } from 'src/lib/utils';
import {
  GoogleTranslationService,
  Language,
} from './google-translation.service';

/**
 * 언어 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  /**
   * 폴백 언어
   *
   * 선택한 언어도, 브랜드 기본 언어도 이용 불가능할때
   * @readonly
   */
  static readonly FALLBACK_LANG = 'ko';

  /**
   * 현재 선택한 언어
   * @readonly
   */
  readonly lang$: Subject<string> = new Subject();

  /**
   * 이용 가능 언어 세트
   * @readonly
   */
  readonly languageSet$: Subject<Set<string>> = new Subject();

  /**
   * 브랜드 기본 언어
   * @readonly
   */
  readonly brandDefaultLanguage$: Subject<string> = new Subject();

  /**
   * 현재 선택한 언어
   */
  #lang: string = LanguageService.FALLBACK_LANG;

  /**
   * 이용 가능 언어 세트
   */
  #languageSet: Set<string> = new Set();

  /**
   * 브랜드 기본 언어
   */
  #brandDefaultLanguage?: string;

  /**
   * 구글 번역 사용 여부
   */
  private isUsingGoogleTranslation = false;

  /**
   * 현재 선택한 언어
   * @readonly
   */
  get lang(): string {
    return this.#lang;
  }

  /**
   * 이용 가능 언어 세트
   * @readonly
   */
  get languageSet(): Set<string> {
    return this.#languageSet;
  }

  /**
   * 브랜드 기본 언어
   * @readonly
   */
  get brandDefaultLanguage(): string | undefined {
    return this.#brandDefaultLanguage;
  }

  constructor(
    private storageService: StorageService,
    private translateService: TranslateService,
    private googleTranslateService: GoogleTranslationService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  /**
   * 서비스 초기화
   * @param brand 브랜드
   * @param isAdmin 관리자 여부
   * @param isUsingGoogleTranslation 구글 자동번역 여부 (HomeScreen.languageButtonVisible)
   */
  init(brand?: IBrand, isUsingGoogleTranslation = false): void {
    if (brand) {
      // 브랜드 언어
      const { languageListJson, language } = brand;
      const languageList: string[] = Utils.getParsedJson(languageListJson, []);
      // 이용 가능 언어 세트
      this.setLanguageSet(new Set(languageList));
      // 언어 초기화
      this.initLanguage(language);
    } else {
      // 이용 가능 언어 세트
      this.setLanguageSet(new Set([LanguageService.FALLBACK_LANG]));
      // 언어 초기화
      this.initLanguage(LanguageService.FALLBACK_LANG);
    }

    // HomeScreen.languageButtonVisible
    // null일 가능성도 있으므로 boolean으로 변환
    this.isUsingGoogleTranslation = !!isUsingGoogleTranslation;

    if (this.isUsingGoogleTranslation) {
      this.googleTranslateService.init();
    }
  }

  /**
   *  표시 언어 상태를 변경하고 스토리지에 저장
   */
  changeLang(lang?: string, isInit = false): void {
    let langToUse: string;

    if (lang && this.languageSet.has(lang)) {
      langToUse = lang;
    } else {
      console.warn(`Language: ${lang} not supported`);
      langToUse = this.brandDefaultLanguage || LanguageService.FALLBACK_LANG;
    }

    // 브라우저 로컬스토리지
    this.storageService.set('lang', langToUse);

    setTimeout(() => {
      // 언어 변경시
      if (!isInit) {
        // 새로고침
        window.location.reload();
        return;
      }

      // 언어 변경시엔 로컬스토리지 저장만 하고, 초기화할때 변경 적용
      // html lang 속성
      this.document.documentElement.lang = langToUse;
      // 언어 변경 전파
      this.setLang(langToUse);
      // ngx-translate
      this.translateService.use(langToUse);

      // 구글 번역 사용 설정되있을때만
      if (this.isUsingGoogleTranslation) {
        // 구글 웹사이트 번역
        this.googleTranslateService.changeLanguage(langToUse as Language);
      }
    });
  }

  /**
   * 언어 초기화
   */
  private initLanguage(language?: string): void {
    // 브라우저에 저장된 언어
    const storedLang = this.storageService.get('lang');
    // 브랜드 기본 언어
    this.setBrandDefaultLanguage(language || LanguageService.FALLBACK_LANG);
    // 언어 변경
    this.changeLang(storedLang || this.brandDefaultLanguage, true);
  }

  /**
   * 현재 선택한 언어
   *
   * 외부에서 수정 불가하도록 접근 제한
   */
  private setLang(value: string) {
    this.#lang = value;
    this.lang$.next(value);
  }

  /**
   * 이용 가능 언어 세트
   *
   * 외부에서 수정 불가하도록 접근 제한
   */
  private setLanguageSet(value: Set<string>) {
    this.#languageSet = value;
    this.languageSet$.next(value);
    this.translateService.addLangs([...value]);
    this.googleTranslateService.includedLanguages = [...value] as Language[];
  }

  /**
   * 브랜드 기본 언어
   *
   * 외부에서 수정 불가하도록 접근 제한
   */
  private setBrandDefaultLanguage(value: string) {
    this.#brandDefaultLanguage = value;
    this.brandDefaultLanguage$.next(value);
    this.translateService.setDefaultLang(value);
  }
}
