import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService } from '../services/language.service';
import { Utils } from '../utils';

@Pipe({
  name: 'locale',
  pure: false,
  standalone: true,
})
export class LocalePipe implements PipeTransform, OnDestroy {
  /**
   * 언어 변경 구독
   */
  onLangChange?: Subscription;

  /**
   * 기본 언어 변경 구독
   */
  onDefaultLangChange?: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private languageService: LanguageService,
  ) {}

  transform(value: any, key: string, lang = this.languageService.lang): string {
    if (!value || !key) {
      return '';
    }

    if (!this.onLangChange) {
      this.onLangChange = this.languageService.lang$.subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });
    }

    if (!this.onDefaultLangChange) {
      this.onDefaultLangChange =
        this.languageService.brandDefaultLanguage$.subscribe(() => {
          this.changeDetectorRef.markForCheck();
        });
    }

    if (typeof value === 'string') {
      return value;
    }

    return Utils.getDataI18n(value, key, lang);
  }

  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
    this.onDefaultLangChange?.unsubscribe();
  }
}
