import { Injectable } from '@angular/core';
import { BrandFeature, Feature } from 'src/lib/auth/account.model';
import { IBrand } from 'src/lib/repository/brand/brand.model';
import { Utils } from 'src/lib/utils';

/**
 * 브랜드 정보 관리, 기능 이용 가능 여부 검사 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class BrandService {
  /**
   * 브랜드
   */
  #brand?: IBrand;

  /**
   * 브랜드 기능
   */
  private brandFeature?: BrandFeature;

  /**
   * 브랜드
   * @readonly
   */
  get brand(): IBrand {
    return this.#brand!;
  }

  constructor() {}

  /**
   * 서비스 초기화
   */
  init(brand: IBrand): void {
    this.#brand = brand;
    this.brandFeature = Utils.getParsedJson(brand.brandFeatureJson, {});
  }

  /**
   * 이용 가능 여부
   */
  isAvail(...featureList: Feature[]): boolean {
    // 권한 입력은 필수
    if (!featureList?.length) {
      throw new Error('Feature is required');
    }

    if (!this.brandFeature) {
      return false;
    }

    // 모두 통과해야 이용 가능
    const hasFeature = featureList.every((feature) => {
      return this.brandFeature![feature];
    });

    return hasFeature;
  }
}
