import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Optional,
  Renderer2,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[inputCheck]',
  standalone: true,
})
export class InputCheckDirective {
  /**
   * regular expression string
   */
  @Input() inputCheck?: string;

  /**
   * transform alphabet to uppercase or lowercase
   */
  @Input() caseTo?: 'upper' | 'lower';

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private renderer2: Renderer2,
    @Optional() private ngControl: NgControl,
  ) {}

  /**
   * IME 사용 언어는 beforeinput 이벤트에서 preventDefault() 호출해도 bubble 발생하므로 input 이벤트에서 검사
   */
  @HostListener('input', ['$event'])
  onInput(inputEvent: InputEvent): void {
    inputEvent.preventDefault();

    const { value, selectionStart, selectionEnd, selectionDirection } =
      this.elementRef.nativeElement;
    const transformed = this.getCaseTransformed(value);
    const [passed, failed] = this.getTestedValue(transformed);
    this.setValue(passed);

    // 값을 통째로 바꾸면 커서가 오른쪽 끝으로 이동하기때문에 원래 위치로 재이동
    this.elementRef.nativeElement.setSelectionRange(
      // 정규식 통과하지 못한 문자열 길이만큼 왼쪽으로 이동
      selectionStart! - failed.length,
      selectionEnd! - failed.length,
      selectionDirection!,
    );
  }

  private getCaseTransformed(value: string): string {
    if (!value) {
      return '';
    }

    if (this.caseTo === 'upper') {
      return value.toUpperCase();
    }

    if (this.caseTo === 'lower') {
      value.toLowerCase();
    }

    return value;
  }

  private getTestedValue(value: string): [passed: string, failed: string] {
    if (!this.inputCheck || !value) {
      return ['', ''];
    }

    let passed = '';
    let failed = '';

    // 붙여넣기 할수도 있으므로 모든 문자를 검사
    value.split('').forEach((char) => {
      if (new RegExp(this.inputCheck!).test(char)) {
        passed += char;
      } else {
        failed += char;
      }
    });

    return [passed, failed];
  }

  private setValue(value: string): void {
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.setValue(value);
    } else {
      this.renderer2.setProperty(this.elementRef.nativeElement, 'value', value);
    }
  }
}
