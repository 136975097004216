import { Injectable } from '@angular/core';

/**
 * 글로벌 로딩 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  /**
   * css 투명도 스타일
   */
  opacity = 0;

  /**
   * css 디스플레이 스타일
   */
  display: 'flex' | 'none' = 'none';

  /**
   * css 애니메이션 지속시간(밀리초)
   */
  animationDurationMs = 300;

  /**
   * 로딩 여부
   */
  isLoading = false;

  constructor() {}

  /**
   * 로딩 시작
   */
  start(): void {
    this.display = 'flex';
    this.isLoading = true;

    // 같은 스코프에서 실행하면 애니메이션 재생 안됨
    setTimeout(() => {
      if (this.isLoading) {
        this.opacity = 1;
      }
    });
  }

  /**
   * 로딩 중지
   */
  stop(): void {
    this.opacity = 0;
    this.isLoading = false;

    // 애니메이션 종료 후 디스플레이 스타일 변경. 바로 사라지지 않고 페이드아웃 되듯이.
    setTimeout(() => {
      if (!this.isLoading) {
        this.display = 'none';
      }
    }, this.animationDurationMs);
  }
}
